<template>
  <base-bg>
    <div>
      <top-nav-bar></top-nav-bar>

      <section class="page with-footer">
        <div class="container">
          <h1 class="text-handwriting text-center">Meine Favoriten</h1>
          <base-list
            :items="elements"
            :page="$routeNames.routeRecipePage"
            :showLikes="true"
          ></base-list>
        </div>
      </section>
    </div>
  </base-bg>
</template>
  
  <script>
import { mapGetters } from "vuex";
export default {
  inject: ["activePage"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getRecipes", "getRecipeSearch"]),
    elements() {
      const items = this.getRecipes.slice();

      const filteredItems = items.filter((x) => x.likedByUser);

      return filteredItems;
    },
  },
  methods: {},
  created() {
    this.activePage.value = this.$routeNames.routeLikedRecipesPage;
  },
};
</script>
  
  <style scoped>
</style>
  